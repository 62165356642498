import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router';
import Authorize from './Authorize';
import config from './lib/config';
import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import ErrorBoundary from './ErrorBoundary';
import Logout from './Logout';
import OIDCLogout from './OIDCLogout';
import './index.css';
import ManageProfile from './ManageProfile';
import { FrameRestricter } from './FrameRestricter';
import LoginCallbackError from './LoginCallbackError';
import URI from 'urijs';
import Loader from './Loader';
import InvalidRoute from './InvalidRoute';
import { getOktaAuthInstance } from './lib/okta-utils';
import { handleScopeChange } from './lib/scope-mapping-utils';


//TO DO After Staggered Context Release we can bring back the webpack chunks Refer to RUAB-1836


const oktaAuth = getOktaAuthInstance();

function App() {

  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin));
  };

  const { routePath, search, hash } = handleScopeChange();
  return (
    <FrameRestricter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <ErrorBoundary>
          <Suspense fallback={Loader}>
            <Routes>
              <Route path="oauth2/v1/authorize" element={<Navigate to={new URI(`/oauth2/${config.primaryAuthorizationServer.id}/v1/authorize`)
                .search(search)
                .hash(hash)
                .toString()} replace/>}>
              </Route>
              <Route path="oauth2/v1/fhir/authorize" element={<Navigate to={new URI(`/oauth2/${config.primaryAuthorizationServer.id}/v1/authorize`)
                .search(search)
                .hash(hash)
                .addQuery({ fhir: true })
                .toString()} replace/>}/>
              <Route path="fhir/v1/authorize" element={<Navigate to={new URI(`/oauth2/${config.primaryAuthorizationServer.id}/v1/authorize`)
                .search(search)
                .hash(hash)
                .addQuery({ fhir: true })
                .toString()} replace/>}/>
              <Route path="oauth2/:authserverid/v1/authorize" element={
                !routePath ? <Authorize/> :
                  <Navigate to={new URI(routePath)
                    .search(search)
                    .hash(hash)
                    .toString()} replace/>
              }/>
              <Route path="oauth2/v1/login_callback_lwa_spa" element={
                <LoginCallback errorComponent={LoginCallbackError} loadingElement={<Loader />}/>
              }/>
              <Route path="oauth2/v1/logout" element={<OIDCLogout/>}/>
              <Route path="oauth2/:authServerId/v1/logout" element={<OIDCLogout/>}/>
              <Route path="/logout" element={<Logout/>}/>
              <Route path="profile/manage" element={<ManageProfile/>}/>
              <Route path="/*" element={<InvalidRoute/>}/>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Security>
    </FrameRestricter>
  );

}

export default App;
