import  { nanoid }  from 'nanoid';
import Cookies from 'universal-cookie';
import constants from './constants';

const LWA_LOGIN_TIMES = 'lwa_login_times_spa';
const cookies = new Cookies();
const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;
const LWA_UNIQ_ID_COOKIE = 'lwa_uniq_id_spa';
const ATHENA_OAUTH_NONCE_COOKIE = 'athena-oauth-nonce-spa';
const ATHENA_AUTHORIZE_URL_COOKIE = 'athena-authorizeUrl';
const ATHENA_OAUTH_COOKIE_MIN_TO_LIVE = 10;
const MINUTE_MILLISECONDS = 60 * 1000;
const PRE_AUTHN_CONFIGS = 'athena-pre-authn-configs';
const COOKIE_OPTIONS = Object.freeze({ path: '/', httpOnly: false, secure: true });

export function setAndGetLwaUniqId() {
  if (!cookies.get(LWA_UNIQ_ID_COOKIE)) {
    const lwaUniqId = nanoid();
    // eslint-disable-next-line max-len
    cookies.set(LWA_UNIQ_ID_COOKIE, lwaUniqId, { ...COOKIE_OPTIONS, expires: new Date(Date.now() + (DAY_MILLISECONDS * 7)) });
  }
  return cookies.get(LWA_UNIQ_ID_COOKIE);
}

export function setLoginVerificationValues({ state, nonce }) {
  const options = { ...COOKIE_OPTIONS, expires: new Date(Date.now() + (ATHENA_OAUTH_COOKIE_MIN_TO_LIVE * 60 * 1000)) };
  cookies.set(constants.ATHENA_OAUTH_STATE_COOKIE, state, options);
  cookies.set(ATHENA_OAUTH_NONCE_COOKIE, nonce, options);
}

export function getOAuthState() {
  return cookies.get(constants.ATHENA_OAUTH_STATE_COOKIE);
}

export function getLwaLoginTimes() {
  return cookies.get(LWA_LOGIN_TIMES);
}

export function setLwaLoginCookie(lwaLoginTimes) {
  return cookies.set(LWA_LOGIN_TIMES, lwaLoginTimes, COOKIE_OPTIONS);
}

export function removeLoginCookies() {
  cookies.remove(constants.ATHENA_OAUTH_STATE_COOKIE);
  cookies.remove(ATHENA_OAUTH_NONCE_COOKIE);
}

//Below cookies are read by OHSW, that is why they must be set on .athenahealth.com
const crossDomainCookieOptions = () => {
  return { ...COOKIE_OPTIONS, domain: '.athenahealth.com', expires: new Date(Date.now() + MINUTE_MILLISECONDS * 10) };
};

export function recordAuthorizeUrl(url) {
  cookies.set(ATHENA_AUTHORIZE_URL_COOKIE, url, crossDomainCookieOptions());
}

export function recordPreAuthnConfigs(preAuthnConfigs) {
  cookies.set(PRE_AUTHN_CONFIGS,
    JSON.stringify(preAuthnConfigs), { ...crossDomainCookieOptions(), expires: undefined });
}
