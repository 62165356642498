/* eslint-disable camelcase */

import { useLocation } from 'react-router';

function getAdditionalScopes(requestedScope) {
  const scopeMappingObject = window._AthenaIdentityScopeMapping;
  if (!scopeMappingObject) {
    return [];
  }
  const scopeMappingKeys = Object.keys(scopeMappingObject);
  if (scopeMappingKeys.includes(requestedScope)) {
    return scopeMappingObject[requestedScope];
  }
  return [];
}

// For Authorize POST method call this from preAuthorize.js directly to add
// HTI1 scopes
export  function addHTI1Scopes (requestedScopes) {
  const requestedupdatedScopesArray = requestedScopes.split(" ");
  const scopesArray = [];
  requestedupdatedScopesArray.forEach(requestedScope => {
    const addScopes = getAdditionalScopes(requestedScope);
    if (addScopes && addScopes.length > 0) {
      scopesArray.push(addScopes.concat());
    }
  });
  scopesArray.push(requestedupdatedScopesArray.concat());
  const updatedScopesArray = [].concat.apply([], scopesArray);
  const uniqueScopeArray = updatedScopesArray.filter(function(scope, position) {
    return updatedScopesArray.indexOf(scope) === position;
  });
  const scopeScipt = uniqueScopeArray.join(" ");
  return scopeScipt;
}

export function handleScopeChange () {
  // eslint-disable-next-line prefer-const
  let { search, hash, pathname } = useLocation();
  let routePath;
  let updatedSearch;
  const searchParams = new URLSearchParams(search);
  const requestedScopes = searchParams.get('scope');
  if (requestedScopes) {
    const scopeStringRevised = addHTI1Scopes(requestedScopes);
    if (scopeStringRevised !== requestedScopes) {
      searchParams.delete('scope');
      searchParams.set('scope', scopeStringRevised);
      updatedSearch = new URLSearchParams(searchParams).toString();
      routePath = pathname;
    }
  }
  const searchSting = updatedSearch ? updatedSearch : search;
  return { routePath, search: searchSting, hash };
}
