/* eslint-disable camelcase */
import amplitude from 'amplitude-js';
import { setAndGetLwaUniqId } from './cookie-utils';
import packageJson from '../../package.json';
import sessionStorageUtil from './session-storage-util';
import { parseAudForMetadata } from './authorize-utils';
import CryptoJS from 'crypto-js';

export function getClientInfo() {
  const url = sessionStorageUtil.getOriginalAuthUrl() || window.location.href;
  const urlParams = new URL(url).searchParams;
  const clientId = urlParams.get('client_id');
  const aud = urlParams.get('aud');
  const idp = urlParams.get('idp');
  const clientInfo = {
    clientId,
    idp,
  };
  if (aud) {
    const { practiceId, brandId } = parseAudForMetadata(aud);
    if (practiceId) {
      return { ...clientInfo, practiceId, brandId };
    }
  }
  return clientInfo;
}

function isWhiteListedEvent(eventName) {
  // _AthenaIdentityAmplituedEventWhitelist is defined in
  // https://bitbucket.athenahealth.com/projects/IAM/repos/identity-amplitude-event-whitelist/browse
  if (window._AthenaIdentityAmplituedEventWhitelist && Array.isArray(window._AthenaIdentityAmplituedEventWhitelist)) {
    return window._AthenaIdentityAmplituedEventWhitelist.includes(eventName);
  }
  // This whitelist is to help us reduce the cost on Amplitude, not an access restriction.
  // So if there is an issue getting the whitelist, we don't want to miss any necessary log
  // ,and we will just log all events.
  return true;
}

/**
 * logAmplitude - Called to send an event to the Amplitude server.
 *
 * @param {String} eventName The name of the event to track.
 * @param {Object} eventProperties Object of properties to send with the event.
 * @param {function} callback An optional callback function to be called after logEvent completes.
 *                            Useful when page navigation may happen before event logging is complete
 * @param {string} url Url Value used to extract client_id value
 * @returns {none} none
 **/
export function logAmplitude({ eventName, eventProperties }) {

  if (!isWhiteListedEvent(eventName)) return;

  const clientInfo = getClientInfo();
  const sendProperties = {
    signin_widget_version: process.env.REACT_APP_AOSW_VERSION,
    lwa_uniq_id: setAndGetLwaUniqId(),
    spa_app_version: packageJson.version,
    client_id: clientInfo.clientId,
    idp: clientInfo.idp,
    practice_id: clientInfo.practiceId,
    brand_id: clientInfo.brandId,
    ...eventProperties,
  };

  return Promise.race([
    new Promise((resolve) => {
      amplitude.getInstance().logEvent(eventName, sendProperties, resolve);
    }),
    // Ignore timeouts/errors by resolving the promise after 1 second
    new Promise(resolve => { setTimeout(resolve, 1000); }),
  ]);
}

export function logDegradedStateEvent(eventProperties) {
  return logAmplitude({ eventName: 'Landing::DegradedState', eventProperties });
}

export function logDegradedStateErrorEvent(eventProperties) {
  return logAmplitude({ eventName: 'LoadDegradedState::Failure', eventProperties });
}

export function logPreAuthErrorEvent(eventProperties) {
  return logAmplitude({ eventName: 'LoadPreAuth::Failure', eventProperties });
}
export function logPostAuthErrorEvent(eventProperties) {
  return logAmplitude({ eventName: 'LoadPostAuth::Failure', eventProperties });
}

export function logSpaLandingEvent(eventProperties) {
  return logAmplitude({ eventName: 'Login::LandingSPA', eventProperties });
}

export function logThrottleErrorEvent(eventProperties) {
  return logAmplitude({ eventName: 'Login::ThrottleError', eventProperties });
}

export function logReloginEvent(eventProperties){
  return logAmplitude({ eventName: 'Login::ReloginTriggered', eventProperties });
}

export function logKeysProbeFailure(eventProperties) {
  return logAmplitude({ eventName: 'Login::KeysProbeFailure', eventProperties });
}

// Create hashed email as amplitude user_id
export function createHashFromString(str) {
  const hash = CryptoJS.SHA1(str.toLowerCase());
  const base64String = CryptoJS.enc.Base64.stringify(hash);
  return base64String;
}
